import { h, render, Fragment } from "preact";
import Steps from "js/components/design/home/Steps.js";

export default function Borrow() {
  return (
    <div className="bg-highlight-700 overflow-hidden font-inter">
      <div className="relative max-w-7xl mx-auto py-20 px-4 sm:px-6 lg:px-8">
        <div className="flex justify-center items-center">
          <h2 className="mt-2 text-3xl xs:text-2xl lg:mb-10 md:mb-5 md:text-2xl lg:text-3xl leading-8 font-extrabold tracking-tight text-main-50 sm:text-4xl">
            Getting The Funds You Need Should Never Be Difficult.
          </h2>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                <img
                    className="rounded-3xl shadow-lg object-cover object-center"
                    src="/public/images/tc_apply.jpg"
                    alt="Apply"
                    width={1184}
                    height={1376}
                  />
                  {/* <div className="absolute rounded-3xl inset-0 bg-gray-400 mix-blend-multiply" aria-hidden="true" /> */}
                </div>
              </figure>
            </div>
          </div>
          <div className="flex justify-center items-center mt-8 lg:mt-0">
            <Steps />
          </div>
        </div>
      </div>
    </div>
  );
}
