// Must be the first import
if (process.env.NODE_ENV === 'development') {
    // Must use require here as import statements are only allowed
    // to exist at top-level.
    require('preact/debug');
    require('preact/devtools');
}
import 'css/styles.css';
import { h, render } from 'preact';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from 'js/components/User/AuthProvider.js';
import 'js/shared/logging.js';
import Routes from 'js/routes.js';
import * as Sentry from '@sentry/react';
import ErrorBoundary from './shared/ErrorBoundary.js';
import PagesensePatch from './shared/PagesensePatch';

Sentry.init({
    dsn: 'https://f6423d8ad02a91cecf07403443626e60@o4508406412279808.ingest.us.sentry.io/4508893059153920',
    integrations: [],
    tracesSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 0.1,
    environment: process.env.NODE_ENV,
});

const App = () => {
    return (
        <ErrorBoundary>
            <PagesensePatch>
                <AuthProvider>
                    <Router>
                        <Routes />
                    </Router>
                </AuthProvider>
            </PagesensePatch>
        </ErrorBoundary>
    );
};

render(<App />, document.getElementById('app'));
